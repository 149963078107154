const resultComps = {}

const requireComponent = require.context(
  './', // 在當前目錄下查找
  false, // 不遍歷子文件夾
  /\.vue$/ // 正則匹配 以 .vue結尾的文件
)

requireComponent.keys().forEach(fileName => {
  const comp = requireComponent(fileName)
  resultComps[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = comp.default
})

export default resultComps
