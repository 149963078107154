//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import 'moment/locale/zh-cn'

const checkboxOptions = [
  { label: 'Apple', value: '1' },
  { label: 'Pear', value: '2' },
  { label: 'Orange', value: '3', disabled: true }
]
export default {
  data() {
    return {
      moment,
      checkboxOptions,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 } // 靈活調整寬度，保持表單 label 寬度統一， 註意與 wrapperCol 合計24即可
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      },
      form: this.$form.createForm(this, { name: 'carApplyForm' })
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    init(data) {
      if (!data) {
        return
      }
      // console.log('data', data)
      const formData = JSON.parse(data)

      this.form.setFieldsValue(formData)
      // 時間類型處理
      if (formData.dateVal) {
        this.form.setFieldsValue({ dateVal: moment(formData.dateVal) })
      }
    },
    /**
     * 重置方法
     */
    reset() {
      this.form.resetFields()
    },
    /**
     * 存為草稿時獲取表單數據
     */
    getDataForDraft() {
      return new Promise((resolve, reject) => {
        const {
          form: { getFieldsValue, getFieldValue }
        } = this
        try {
          const formData = getFieldsValue()
          // 時間類型處理
          formData.dateVal = moment(getFieldValue('dateVal')).format('YYYY-MM-DD')
          resolve(formData)
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * 發布時獲取表單數據
     */
    getDataForSubmit() {
      return new Promise((resolve, reject) => {
        try {
          this.form.validateFields((err, values) => {
            // 時間類型處理
            values.dateVal = moment(values.dateVal).format('YYYY-MM-DD')

            err ? reject(err) : resolve(values)
          })
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
